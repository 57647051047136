import { useSearchParams } from '@remix-run/react';

import { useLiveCallback } from '../../hooks/useLiveCallback';

export function usePromotionCode(queryParam = 'promotion-code') {
  const [params, setParams] = useSearchParams();
  const promotionCode = params.get(queryParam);
  const handleSetPromotionCode = useLiveCallback((code: string | null) => {
    setParams(
      (prev) => {
        if (prev.get(queryParam) === code) return prev;

        const params = new URLSearchParams(prev);
        if (code) {
          params.set(queryParam, code);
        } else {
          params.delete(queryParam);
        }
        return params;
      },
      {
        replace: true,
      }
    );
  });

  return [promotionCode, handleSetPromotionCode] as const;
}
