import { useCustomCheckout } from '@stripe/react-stripe-js';
import { type StripeCustomCheckoutDiscountAmount } from '@stripe/stripe-js';
import pluralize from 'pluralize';
import { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import useSWRImmutable from 'swr/immutable';
import { match } from 'ts-pattern';

import {
  type DtoCoupon,
  StripeCouponDuration,
} from '@lp-lib/api-service-client/public';

import { usePaymentAnalytics } from '../../analytics/payment';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { apiService } from '../../services/api-service';
import { formatCurrency } from '../../utils/currency';
import { XIcon } from '../icons/XIcon';
import { Loading } from '../Loading';
import { usePromotionCode } from './usePromotionCode';

function formatOff(coupon: DtoCoupon, amountOff: number) {
  if (coupon.amountOff > 0)
    return `${formatCurrency(
      Math.min(coupon.amountOff, amountOff) / 100,
      true
    )} off`;
  return `${coupon.percentOff}% off`;
}

function PromotionCodeDescription(props: { code: string; amountOff: number }) {
  const { code, amountOff } = props;

  const { data: promotionCode } = useSWRImmutable(
    ['/stripe/promotion-code', code],
    async () => {
      const resp = await apiService.stripe.queryPromotionCodes({
        code,
        active: true,
      });
      return resp.data.promotionCodes[0];
    }
  );

  if (!promotionCode) return null;

  const coupon = promotionCode.coupon;

  return (
    <div className='ml-2 mt-1 text-secondary text-2xs'>
      {match(coupon.duration)
        .with(StripeCouponDuration.CouponDurationForever, () =>
          formatOff(coupon, amountOff)
        )
        .with(
          StripeCouponDuration.CouponDurationOnce,
          () => `${formatOff(coupon, amountOff)} for 1 billing cycle`
        )
        .with(
          StripeCouponDuration.CouponDurationRepeating,
          () =>
            `${formatOff(coupon, amountOff)} for ${pluralize(
              'month',
              coupon.durationInMonths,
              true
            )}`
        )
        .with(
          StripeCouponDuration.CouponDurationVariable,
          () =>
            `${formatOff(coupon, amountOff)} for ${pluralize(
              'month',
              coupon.durationInMonths,
              true
            )}`
        )
        .exhaustive()}
    </div>
  );
}

function AddingPromotionCode(props: {
  initCode?: string | null;
  autoSubmit?: boolean;
  onApply: (code: string) => void;
  onCancel: () => void;
}) {
  const { initCode, autoSubmit, onApply, onCancel } = props;

  const analytics = usePaymentAnalytics();
  const { applyPromotionCode } = useCustomCheckout();

  const [code, setCode] = useState(initCode || '');
  const ref = useRef<HTMLDivElement>(null);

  const {
    call: apply,
    state: {
      error,
      state: { isRunning },
    },
    reset,
  } = useLiveAsyncCall(async () => {
    const { error } = await applyPromotionCode(code);
    analytics.trackPromotionCodeApplied({
      path: window.location.pathname,
      code,
      errCode: error?.code,
      errMsg: error?.message,
      autoSubmit,
    });

    if (error) {
      throw error;
    }
    onApply(code);
  });

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.currentTarget.value);
    if (error) {
      reset();
    }
  };

  useEffectOnce(() => {
    if (autoSubmit && initCode) {
      apply();
    }
  });

  useOutsideClick(ref, () => {
    if (!!code) return;

    onCancel();
  });

  return (
    <div className='w-full' ref={ref}>
      <div className='relative w-full h-9'>
        <input
          type='text'
          value={code}
          onChange={handleCodeChange}
          placeholder='Enter promotion code'
          maxLength={20}
          className={`w-full h-full rounded-lg ${
            error ? 'field-error' : 'field'
          } mt-0`}
          disabled={isRunning}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              apply();
            }
          }}
        />
        <div className='absolute top-0 right-3 h-full'>
          {isRunning ? (
            <Loading text='' containerClassName='h-full opacity-50' />
          ) : (
            <button
              type='button'
              className='h-full btn text-white'
              onClick={apply}
            >
              Apply
            </button>
          )}
        </div>
      </div>
      {error && (
        <div className='text-red-500 text-2xs mt-1'>{error.message}</div>
      )}
    </div>
  );
}

function AppliedDiscount(props: {
  discounts: StripeCustomCheckoutDiscountAmount[];
  onRemove: () => void;
}) {
  const { discounts, onRemove } = props;

  const discount = discounts[0];
  const { removePromotionCode, recurring } = useCustomCheckout();
  const analytics = usePaymentAnalytics();

  const {
    call: handleRemove,
    state: {
      error,
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const { error } = await removePromotionCode();
    analytics.trackPromotionCodeRemoved({
      path: window.location.pathname,
      code: discount.promotionCode,
      errCode: error?.code,
      errMsg: error?.message,
    });

    if (error) {
      throw error;
    }
    onRemove();
  });

  const saving = recurring?.trial
    ? recurring.dueNext.amountDiscount
    : discount.amount;

  return (
    <div className='w-full flex flex-col gap-1'>
      <div key={discount.promotionCode} className='w-full flex justify-between'>
        <div>
          <div
            className={`
              h-9 rounded-lg text-white bg-secondary border border-solid border-secondary 
              pl-3 pr-2 flex justify-between items-center gap-3
              ${isRunning ? 'opacity-50' : ''}
           `}
          >
            {discount.promotionCode}
            {isRunning ? (
              <Loading text='' imgClassName='w-4 h-4' />
            ) : (
              <button
                type='button'
                className={`btn text-secondary hover:text-white`}
                onClick={handleRemove}
              >
                <XIcon className='w-4 h-4 fill-current' />
              </button>
            )}
          </div>
          {recurring && discount.promotionCode && (
            <PromotionCodeDescription
              code={discount.promotionCode}
              amountOff={saving}
            />
          )}
        </div>

        <div className='h-9 flex-none flex justify-center items-center tabular-nums text-green-001'>
          -{formatCurrency(saving / 100, true)}
        </div>
      </div>

      {error && (
        <div className='text-red-500 text-2xs mt-1'>{error.message}</div>
      )}
    </div>
  );
}

interface AddPromotionCodeCommand {
  initCode?: string | null;
  autoSubmit?: boolean;
}

export function Discount(props: { queryParam?: string }) {
  const { queryParam } = props;

  const { discountAmounts } = useCustomCheckout();
  const analytics = usePaymentAnalytics();
  const [promotionCode, setPromotionCode] = usePromotionCode(queryParam);

  const [addPromotionCodeCommand, setAddPromotionCodeCommand] =
    useState<AddPromotionCodeCommand | null>(null);

  const pushCommand = useLiveCallback((cmd: AddPromotionCodeCommand) => {
    const isApplied =
      cmd.initCode &&
      discountAmounts?.some((d) => d.promotionCode === cmd.initCode);
    if (isApplied) {
      console.log('Promotion code already applied');
      return;
    }
    setAddPromotionCodeCommand(cmd);
  });

  // sync promotion code from query param
  useEffect(() => {
    if (!promotionCode) return;
    pushCommand({ initCode: promotionCode, autoSubmit: true });
  }, [promotionCode, pushCommand]);

  if (addPromotionCodeCommand) {
    return (
      <AddingPromotionCode
        {...addPromotionCodeCommand}
        onCancel={() => setAddPromotionCodeCommand(null)}
        onApply={(code) => {
          setAddPromotionCodeCommand(null);
          setPromotionCode(code);
        }}
      />
    );
  }

  if (discountAmounts && discountAmounts.length > 0) {
    return (
      <AppliedDiscount
        discounts={discountAmounts}
        onRemove={() => {
          setAddPromotionCodeCommand(null);
          setPromotionCode(null);
        }}
      />
    );
  }

  return (
    <button
      type='button'
      className='btn-secondary w-40 h-9 rounded-lg'
      onClick={() => {
        analytics.trackAddPromotionCodeButtonClicked({
          path: window.location.pathname,
        });
        setAddPromotionCodeCommand({});
      }}
    >
      Add promotion code
    </button>
  );
}
