import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useAnalytics } from './AnalyticsContext';

class PaymentAnalytics {
  constructor(private readonly analytics: AnalyticsBrowser) {}

  trackAddPromotionCodeButtonClicked(props?: EventProperties) {
    this.analytics.track('Add Promotion Code Button Clicked', props);
  }

  trackPromotionCodeApplied(props?: EventProperties) {
    this.analytics.track('Promotion Code Applied', props);
  }

  trackPromotionCodeRemoved(props?: EventProperties) {
    this.analytics.track('Promotion Code Removed', props);
  }
}

export function usePaymentAnalytics(): PaymentAnalytics {
  const analytics = useAnalytics();

  return useMemo(() => new PaymentAnalytics(analytics), [analytics]);
}
