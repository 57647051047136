import { CustomCheckoutProvider } from '@stripe/react-stripe-js';
import {
  type Appearance,
  loadStripe,
  type StripeElementsOptions,
} from '@stripe/stripe-js';

import config from '../../config';

const stripePromise = loadStripe(config.stripe.publishableKey, {
  betas: ['custom_checkout_beta_2'],
});

const STRIPE_APPEARANCE: Appearance = {
  theme: 'stripe',
  rules: {
    '.Label': {
      color: '#ffffff',
    },
    '.CheckboxLabel': {
      color: '#8B9294',
    },
  },
};

export function StripeCustomCheckoutProvider(props: {
  clientSecret: string;
  children: React.ReactNode;
}) {
  const elementsOptions: StripeElementsOptions = {
    appearance: STRIPE_APPEARANCE,
  };

  return (
    <CustomCheckoutProvider
      stripe={stripePromise}
      options={{ clientSecret: props.clientSecret, elementsOptions }}
    >
      {props.children}
    </CustomCheckoutProvider>
  );
}
